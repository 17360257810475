import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SIcon, SImage, SLoad, SNavigation, SPage, SPopup, STable2, SText, SView } from 'servisofts-component';
import FloatButtom from '../../../Components/FloatButtom';
import Parent from '../index'
import SSocket from 'servisofts-socket';

class Lista extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.key_rol = SNavigation.getParam('key_rol');
        this.key_servicio = SNavigation.getParam('key_servicio');

    }

    getContent() {
        var data = Parent.Actions.getAll(this.key_rol, this.key_servicio, this.props);
        if (!data) return <SLoad />;
        return <STable2
            header={[
                { key: "index", label: "#", width: 50 },
                { key: "key_usuario", label: "Usuario", width: 250 },
                {
                    key: "key-eliminar", label: "Eliminar", width: 70, center: true, component: (key) => {
                        return <SView width={35} height={35} onPress={() => {
                            SPopup.confirm({
                                title: "Eliminar",
                                message: "¿Esta seguro de eliminar?",
                                onPress: () => {
                                    Parent.Actions.eliminar(data[key], this.key_servicio, this.props)
                                }
                            })
                        }}>
                            <SIcon name={'Delete'} />
                        </SView>
                    }
                },
            ]}
            data={data}
            filter={(data) => {
                if(data.estado != 1) return false;
                return true;
            }}    
        />
    }
    render() {
        return (
            <SPage title={'Lista de ' + Parent.component} disableScroll center>
                {this.getContent()}
                <FloatButtom onPress={() => {
                    SNavigation.navigate(Parent.component + "/registro", { key_rol: this.key_rol, key_servicio: this.key_servicio })
                }} />
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Lista);