import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SButtom, SForm, SHr, SPage, SText, SNavigation, SLoad, SView, SIcon } from 'servisofts-component';
import Parent from '../index'
// import Kolping from '../../../../../Components/Kolping';
class Registro extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.key = SNavigation.getParam("key");
        this.key_rol = SNavigation.getParam("key_rol");
        this.key_servicio = SNavigation.getParam('key_servicio');

    }
    getContent() {
        if (this.key) {
            this.data = Parent.Actions.getByKey(this.key, this.key_servicio, this.props);
            if (!this.data) return <SLoad />
        } else {
            this.data = {};
        }
        var reducer = this.props.state[Parent.component + "Reducer"];
        if ((reducer.type == "registro" || reducer.type == "editar") && reducer.estado == "exito") {
            reducer.estado = "";
            SNavigation.goBack();
        }
        return <SForm
            ref={(form) => { this.form = form; }}
            col={"xs-11 sm-9 md-7 lg-5 xl-4"}
            inputProps={{
                customStyle: "calistenia"
            }}
            inputs={{
                key_usuario: { label: "key_usuario", isRequired: true, defaultValue: this.data["key_usuario"], icon: <SIcon name={"InputUser"} width={40} height={30} /> },
            }}
            onSubmitName={"Aceptar"}
            onSubmit={(values) => {
                values.key_rol = this.key_rol;
                if (this.key) {
                    Parent.Actions.editar({
                        ...this.data,
                        ...values
                    }, this.key_servicio, this.props);
                } else {
                    Parent.Actions.registro(values, this.key_servicio, this.props);
                }
            }}
        />
    }
    render() {
        return (
            <SPage title={'Registro de ' + Parent.component} center>
                <SView height={30}></SView>
                {this.getContent()}
                <SHr />

            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Registro);