import { SPageListProps } from 'servisofts-component'

import InicioPage from "./InicioPage";
import CargaPage from './CargaPage/index';
import AjustesPage from './AjustesPage';
import Servicios from './Servicios';

import Page from './Page';
import rol from './rol';
import permiso from './permiso';
import rolPermiso from './rolPermiso';
import usuarioRol from './usuarioRol';
const Pages: SPageListProps = {
    "inicio": InicioPage,
    "carga": CargaPage,
    AjustesPage,
    ...Servicios.Pages,
    ...Page.Pages,
    ...rol.Pages,
    ...permiso.Pages,
    ...rolPermiso.Pages,
    ...usuarioRol.Pages

}


export const Reducers = {
    ...Servicios.Reducers,
    ...Page.Reducers,
    ...rol.Reducers,
    ...permiso.Reducers,
    ...rolPermiso.Reducers,
    ...usuarioRol.Reducers
}
export default Pages;