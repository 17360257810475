import SSocket from 'servisofts-socket';
import Parent from './index';

export default class Actions {


    static getByServicio = (key_servicio, props) => {
        var reducer = props.state[Parent.component + "Reducer"];
        if ((key_servicio) != reducer.key_servicio) {
            reducer.data = null;
        }
        reducer.key_servicio = key_servicio;
        var data = reducer.data;
        if (!data) {
            if (reducer.estado == "cargando") return null;
            SSocket.send({
                component: Parent.component,
                version: Parent.version,
                type: "getAll",
                estado: "cargando",
                key_usuario: "admin",
                servicio: {
                    key: key_servicio
                }
            })
            return null;
        }
        return data;
    }
    static getAll = (key_page, key_servicio, props) => {
        var reducer = props.state[Parent.component + "Reducer"];
        if ((key_servicio + key_page) != reducer.key_servicio) {
            reducer.data = null;
        }
        reducer.key_servicio = key_servicio + key_page;
        var data = reducer.data;
        if (!data) {
            if (reducer.estado == "cargando") return null;
            SSocket.send({
                component: Parent.component,
                version: Parent.version,
                type: "getAll",
                estado: "cargando",
                key_usuario: "admin",
                key_page: key_page,
                servicio: {
                    key: key_servicio
                }
            })
            return null;
        }
        return data;
    }
    static getByKey = (key, key_page, key_servicio, props) => {
        var data = this.getAll(key_page, key_servicio, props);
        if (!data) return null;
        return data[key];
    }
    
    static registro = (data, key_servicio, props) => {
        SSocket.send({
            component: Parent.component,
            version: Parent.version,
            type: "registro",
            estado: "cargando",
            key_usuario: "",
            data: data,
            servicio: {
                key: key_servicio
            },
        })
    }

    static editar = (data, key_servicio, props) => {
        SSocket.send({
            component: Parent.component,
            version: Parent.version,
            type: "editar",
            estado: "cargando",
            key_usuario: "admin",
            data: data,
            servicio: {
                key: key_servicio
            },
        })
    }

    static eliminar(data, key_servicio, props) {
        var reducer = props.state[Parent.component + "Reducer"];
        var object = {
            component: Parent.component,
            version: Parent.version,
            type: "editar",
            estado: "cargando",
            key_usuario: "admin",
            data: { ...data, estado: 0 },
            servicio: {
                key: key_servicio
            },
        }
        SSocket.send(object);
    }

}