var Upload = /** @class */ (function () {
    function Upload() {
    }
    Upload.send = function (file, url) {
        if (!file)
            return;
        // if (files.length <= 0) return;
        // var form: any = document.createElement("FORM");
        // form.setAttribute("method", "POST");
        // form.setAttribute("enctype", "multipart/form-data");
        var body = new FormData();
        // files.map((file) => {
        body.append('file', file.file);
        // })
        var request = new XMLHttpRequest();
        // request.upload.addEventListener("progress", function (e) {
        //     console.log(e);
        // });        
        request.open('POST', url, true);
        // request.setRequestHeader("Access-Control-Allow-Origin","*")
        // request.setRequestHeader('Content-type', 'multipart/form-data');
        // request.setRequestHeader('Access-Control-Allow-Headers', '*');
        // request.withCredentials = true;
        request.send(body);
    };
    return Upload;
}());
export default Upload;
