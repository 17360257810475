import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SHr, SIcon, SImage, SLoad, SNavigation, SPage, STable2, SText, STheme, SView } from 'servisofts-component';
import FloatButtom from '../../../Components/FloatButtom';
import Page from '../../Page';
import permiso from '../../permiso';
import Parent from '../index'
import SSocket from 'servisofts-socket';
class Lista extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.key_servicio = SNavigation.getParam('key_servicio');
        this.key_rol = SNavigation.getParam('key_rol');

    }

    isActive(permiso) {
        var data = Parent.Actions.getAll(this.key_rol, this.key_servicio, this.props);
        if (!data) return <SLoad />;
        var active = false;
        Object.keys(data).map(key => {
            var obj = data[key];
            if(obj.estado != 1) return;
            if (obj.key_permiso == permiso.key && obj.key_rol == this.key_rol) {
                active = obj;
            }
        })
        if (active) return <SView style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }} center onPress={() => {
            Parent.Actions.eliminar(active, this.key_servicio, this.props);
        }}>
            {/* <SText center fontSize={10} bold>click para desactivar!</SText> */}
        </SView>;
        return <SView style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: STheme.color.primary + "aa",
        }} center onPress={() => {
            Parent.Actions.registro({
                key_rol: this.key_rol,
                key_permiso: permiso.key,
            }, this.key_servicio, this.props);
        }}>
            <SText center fontSize={10} bold>click para activar!</SText>
        </SView>
    }
    getPermisos(page) {
        var permisos = permiso.Actions.getByServicio(this.key_servicio, this.props);
        if (!permisos) return <SLoad />;
        return Object.keys(permisos).map(key => {
            var obj = permisos[key];
            if (obj.key_page == page.key) {
                return <>
                    <SView width={8} />
                    <SView width={70} center card >
                        <SHr />
                        <SView width={30} height={30} style={{
                            borderRadius: 4,
                            overflow: 'hidden',
                        }}>
                            <SImage src={SSocket.api.root + 'permiso/' + obj.key} style={{
                                resizeMode: 'cover'
                            }} />
                        </SView>
                        <SHr />
                        <SText>{obj.descripcion}</SText>
                        <SHr />
                        {this.isActive(obj)}
                    </SView>
                </>
            }
        })
    }
    getPaginas(pages) {
        return Object.keys(pages).map(key => {
            var obj = pages[key];
            return <SView key={key} col={"xs-11 sm-10 md-8 lg-6 xl-4"} center>
                <SHr />
                <SView col={"xs-11"} height={"94%"} card center>
                    <SHr />

                    <SView col={"xs-11"} row height={50} center>
                        <SView width={50} height={50} style={{
                            borderRadius: 4,
                            overflow: 'hidden',
                        }}>
                            <SImage src={SSocket.api.root + 'page/' + obj.key} style={{
                                resizeMode: 'cover'
                            }} />
                        </SView>
                        <SView width={8} />
                        <SView flex >
                            <SText fontSize={16} bold>{obj.descripcion}</SText>
                            <SText fontSize={12}>{"/" + obj.url}</SText>
                        </SView>
                    </SView>
                    <SHr />
                    <SView col={"xs-12"} row>
                        {this.getPermisos(obj)}
                    </SView>
                    <SHr />
                </SView>
                <SHr />
            </SView>
        })

    }
    getContent() {
        var data = Parent.Actions.getAll(this.key_rol, this.key_servicio, this.props);
        if (!data) return <SLoad />;
        var pages = Page.Actions.getAll(this.key_servicio, this.props);
        var permisos = permiso.Actions.getByServicio(this.key_servicio, this.props);
        if (!pages) return <SLoad />;
        if (!permisos) return <SLoad />;
        return <SView col={"xs-12"} center row>
            {this.getPaginas(pages)}
        </SView>
    }
    render() {
        return (
            <SPage title={'Lista de ' + Parent.component} center>
                {this.getContent()}
            </SPage>
        );
    }
}
const initStates = (state) => {
    return { state }
};
export default connect(initStates)(Lista);