import SSocket from 'servisofts-socket';
import Parent from './index';

export default class Actions {


    static getAll = (key_rol, key_servicio, props) => {
        var reducer = props.state[Parent.component + "Reducer"];
        if ((key_servicio + key_rol) != reducer.key_servicio) {
            reducer.data = null;
        }
        reducer.key_servicio = (key_servicio + key_rol);
        var data = reducer.data;
        if (!data) {
            if (reducer.estado == "cargando") return null;
            SSocket.send({
                component: Parent.component,
                version: Parent.version,
                type: "getAll",
                estado: "cargando",
                key_usuario: "admin",
                key_rol: key_rol,
                servicio: {
                    key: key_servicio
                }
            })
            return null;
        }
        return data;
    }
    static getByKey = (key, key_servicio, props) => {
        var data = this.getAll(key_servicio, props);
        if (!data) return null;
        return data[key];
    }


    static registro = (data, key_servicio, props) => {
        SSocket.send({
            component: Parent.component,
            version: Parent.version,
            type: "registro",
            estado: "cargando",
            key_usuario: "",
            data: data,
            servicio: {
                key: key_servicio
            },
        })
    }



    static editar = (data, key_servicio, props) => {
        SSocket.send({
            component: Parent.component,
            version: Parent.version,
            type: "editar",
            estado: "cargando",
            key_usuario: "admin",
            data: data,
            servicio: {
                key: key_servicio
            },
        })
    }
    static eliminar = (data, key_servicio, props) => {
        SSocket.send({
            component: Parent.component,
            version: Parent.version,
            type: "editar",
            estado: "cargando",
            key_usuario: "admin",
            data: {
                ...data,
                estado: 0,
            },
            servicio: {
                key: key_servicio
            },
        })
    }
}